import React, { Component } from "react";
import PropTypes from "prop-types";
import './Recording.css';

class Recording extends Component {
    static propTypes = {
        title: PropTypes.string,
        url: PropTypes.string,
        year: PropTypes.string,
        image: PropTypes.string,
        artist: PropTypes.string
    }
    
    render() {
        return (
            <div className="recording-container">
                <div className="recording-image">
                    <a href={ this.props.url } >
                        <img className="recording-image" src={ this.props.image } alt={ this.props.artist + " | " + this.props.title } />
                    </a>
                </div>
                <div className="recording-title">
                    { this.props.title }
                </div>
                <div className="recording-artist">
                    { this.props.artist }
                </div>
                <div className="recording-year">
                    { this.props.year }
                </div>
            </div>
        );
    }
}

export default Recording;
