import React, { Component } from "react";
import "./Press.css";
import { m } from "framer-motion";

const articles = [
    {
        name: "Exposé Online",
        title: "Reviews: Amanda Chaudhary -- January Suborbital Denomination",
        url: "http://expose.org/index.php/articles/display/amanda-chaudhary-january-suborbital-denomination-3.html"
    },
    {
        name: "KFJC 89.7FM",
        title: "Chaudhary, Amanda – “January Suborbital Denomination” – [CatSynth Records]",
        url: "https://spidey.kfjc.org/51023/chaudhary-amanda-january-suborbital-denomination-catsynth-records/"
    },    
    {
        name: "DisAgreementOnline",
        title: "AMANDA CHAUDHARY – January Suborbital Denomination",
        url: "https://www.disagreement.net/reviews2024/amandachaudhary_januarysuborbitaldenomination.html"
    },
    {
        name: "The Stratton Setlist",
        title: "The New Avant-Garde – Nubdug Ensemble and Amanda Chaudhary Share Cerebral Prog-Jazz-Funk Fusion",
        url: "https://strattonsetlist.com/2022/07/15/nubdug-ensemble-jason-berry-amanda-chaudhary-albums/"
    },
    {
        name: "Sea of Tranquility",
        title: "Chaudhary, Amanda: Meow Meow Band",
        url: "https://www.seaoftranquility.org/reviews.php?op=showcontent&id=23585"
    },
    {   
        name: "Exposé Online",
        title: "Reviews: Nubdug Ensemble — 52 Pickup / A Chip and a Chair and Amanda Chaudhary — Merp Friend",
        url: "http://expose.org/index.php/articles/display/nubdug-ensemble-52-pickup-a-chip-and-a-chair-amanda-chaudhary-merp-friend-3.html"
    },
    {
        name: "Exposé Online",
        title: "Reviews: Amanda Chaudhary — Meow Meow Band",
        url: "http://expose.org/index.php/articles/display/amanda-chaudhary-meow-meow-band-3.html"
    },
    {   name: "The Progressive Aspect",
        title: "A Different Aspect #80",
        url: "https://theprogressiveaspect.net/blog/2022/03/03/a-different-aspect-80/"
    }
];


class Press extends Component {

    render() {
        return (
            <div class="press-main">
                <h2 className="blog-title">ARTICLES AND REVIEWS</h2>
                <div className="press-container">
                    {
                        articles.map ((article) => {
                            return (
                                <div className="press-article">
                                    <div className="press-article-topline">
                                        <span className="press-outlet">{article.name}. </span>
                                        <span className="press-title"><a className="press-title" href={article.url} target="_blank">"{article.title}"</a></span>
                                    </div>
                                </div>
                            )                        
                        })
                    }
                </div>
            </div>
        )
    }
}

export default Press;
export {
    articles
}