import "./index.css";

function Bio () {
    return (
        <div style={{paddingTop:20}}>
            <div style={{float:"left", maxWidth:"50%"}}>
            <p>
             Amanda Chaudhary is a composer, bandleader, electronic musician, jazz keyboardist, and '
             visual/sound artist.   She is also the author of the popular blog CatSynth and its companion 
             video channel CatSynth TV, where she discusses music, art, culture, and of course, cats.
            </p>
            <p>
            She performs regularly around the Bay Area, New York, and beyond, both solo and with various
            bands and ensembles. Her solo work involves experimenting with innovative sounds via analog synthesis 
            and custom software with computers and mobile devices for new modes of expressive musical performance. 
            She often incorporates folk and toy instruments from around the world, along with jazz, dance music and
            other idiomatic styles into her visually captivating performances.   She has performed, recorded, or 
            collaborated with such diverse artists as Amy X Neuburg, G Calvin Weston, Jamaaladeen Tacuma, Rent Romus,
            Tom Djll, Thollem McDonas, Moe! Staiano, Vacuum Tree Head, and David Wessel. Her current ensemble projects 
            include the Nubdug Ensemble; and Pitta of the Mind, an experimental music-and-poetry duo with Maw Shein Win.
            Past projects include Census Designated Place, an experimental jazz and electronic-music combo.
            </p>
            </div>
            <div style={{float:"right", minWidth:"30%", maxwidth:"50%", paddingRight:40}}>
            <img src="photos/AC_Modular_and_Piano.jpg" 
                style={{maxWidth:500,minWidth:300}} alt="Amanda Chaudhary playing a modular synthesizer next to a grand piano"/>
            </div>
        </div>
    )
}

export default Bio;