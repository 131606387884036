import React, { Component } from "react";
import "./index.css";
import Recording from "./Recording";


const recordings = {
    jsd: {
        title: "January Suborbital Denomination",
        image: "album_covers/amanda_chaudhary_jsd_cd_cover.jpg",
        url: "https://amandachaudhary.bandcamp.com/album/january-suborbital-denomination",
        artist: "Amanda Chaudhary",
        year: "2024"
    },
    merpfriend: {
        title: "Merp Friend",
        image: "album_covers/amanda_chaudhary_merp_friend_cover.jpg",
        url: "https://amandachaudhary.bandcamp.com/album/merp-friend",
        artist: "Amanda Chaudhary",
        year: "2022"
    },
    meowmeowband: {
        title: "Meow Meow Band",
        image: "album_covers/amanda_chaudhary_meow_meow_band.jpg",
        url: "https://amandachaudhary.bandcamp.com/album/merp-friend",
        artist: "Amanda Chaudhary",
        year: "2021"
    },
    aquatic: {
        title: "Aquatic",
        image: "album_covers/amanda_chaudhary_aquatic.jpg",
        url: "https://amandachaudhary.bandcamp.com/album/aquatic",
        artist: "Amanda Chaudhary",
        year: "2005"        
    },
    nubdug2: {
        title: "Volume 2: Blame",
        artist: "Nubdug Ensemble",
        url: "https://nubdugensemble.bandcamp.com/album/volume-2-blame",
        year: "2021",
        image: "album_covers/a1970727268_10.jpg",
    },
    nubdug1: {
        title: "Volume One: The Machines of Zeno",
        artist: "Nubdug Ensemble",
        url: "https://nubdugensemble.bandcamp.com/album/volume-one-the-machines-of-zeno",
        year: "2020",
        image: "album_covers/a2997736893_10.jpg"
    },
    nubdugSplit: {
        title: "52 Pickup / A Chip and a Chair",
        artist: "Nubdug Ensemble",
        url: "https://nubdugensemble.bandcamp.com/album/52-pickup-a-chip-and-a-chair-single",
        year: "2022",
        image: "album_covers/a2415556032_10.jpg"
    },
    vth1: {
        title: "Rhizomique",
        artist: "Vacuum Tree Head",
        url: "https://vacuumtreehead.bandcamp.com/album/rhizomique",
        year: "2019",
        image: "album_covers/a1302217260_10.jpg"
    },
    vth2: {
        title: "Botafogos in Shadow Position",
        artist: "Vacuum Tree Head",
        url: "https://vacuumtreehead.bandcamp.com/album/botafogos-in-shadow-position",
        year: "2018",
        image: "album_covers/a4215367176_10.jpg"
    },
    zzaj: {
        title: "ZZAJ - Jazz From The 23rd Century",
        artist: "Various Artists",
        url: "https://discusmusic.bandcamp.com/album/zzaj-jazz-from-the-23rd-century",
        year: "2022",
        image: "album_covers/a1852179096_10.jpg"
    },
    surplus: {
        title: "Arterial Ends Here",
        artist: "Surplus 1980",
        url: "https://moestaiano.bandcamp.com/album/arterial-ends-here",
        year: "2013",
        image: "album_covers/a3915864585_10.jpg"
    },
    rfly1: {
        title: "Off By One",
        artist: "Reconnaissance Fly",
        url: "https://reconnaissancefly.bandcamp.com/album/off-by-one",
        year: "2016",
        image: "album_covers/a1970727268_10.jpg"
    },
    rfly2: {
        title: "Flower Futures",
        artist: "Reconnaissance Fly",
        url: "https://edgetonerecords.bandcamp.com/album/flower-futures",
        year: "2014",
        image: "album_covers/a4278532155_10.jpg"
    }
}

class Music extends Component {


    renderRecording (name) {
        const recording = new Object(recordings[name])
        return (
            <Recording title={recording.title} url={recording.url} year={recording.year} artist={recording.artist} image={recording.image} />
        )
    }

    render () {
        return (
            <div>
                <div className="music-row">
                    <h2 className="blog-title">RELEASES BY AMANDA CHAUDHARY</h2>
                    <div className="recording-row">
                    {
                        ["jsd", "merpfriend", "meowmeowband","aquatic"].map ((name) => this.renderRecording(name))
                    }
                    </div>
                    <p style={{clear: "left"}}>Also available on most popular streaming services.</p>
                    <div style={{clear: "left"}} />
                </div>
                <div className="music-row">
                    <h2 className="blog-title">NUBDUG ENSEMBLE &amp; VACUUM TREE HEAD</h2>
                    <div className="recording-row">
                    {
                        ["nubdug2", "nubdug1", "nubdugSplit","vth1","vth2"].map ((name) => this.renderRecording(name))
                    }
                    <div style={{clear: "left"}} />
                    </div>                    
                </div>
                <div className="music-row">
                    <h2 className="blog-title">OTHER PROJECTS</h2>
                    <div className="recording-row">
                    {
                        ["zzaj", "surplus", "rfly1","rfly2"].map ((name) => this.renderRecording(name))
                    }
                    <div style={{clear: "left"}} />
                    </div>                    
                </div>
            </div>
        );
    }
};

class ShortMusic extends Component {
    renderRecording (name) {
        const recording = new Object(recordings[name])
        return (
            <Recording title={recording.title} url={recording.url} year={recording.year} artist={recording.artist} image={recording.image} />
        )
    }

    render () {
        return (
            <div>
                <div className="recording-row">
                {
                ["jsd", "merpfriend"].map ((name) => this.renderRecording(name))
                }
                </div>     
                <div className="recording-row">
                {
                ["meowmeowband","aquatic"].map ((name) => this.renderRecording(name))
                }
                </div>     
            </div>
        );
    }
}

export {
    Music,
    ShortMusic,
    recordings
}

