import React, { Component } from "react";
import PropTypes from "prop-types";
import "./MenuButton.css"

class MenuButton extends Component {
    static propTypes = {
        label : PropTypes.string,
        name : PropTypes.string,
        url : PropTypes.string
    };

    static defaultProps = {
        title : "UNKNOWN",
        header : "UNKNOWN",
        url : null
      }

    calcBackground () {
        const shade = 128 -  this.props.label.length * 6;
        return "rgba(" + [shade,shade,shade,255].join(",") + ")";
    };

    calcForeground () {
        const shade =  192 +  this.props.label.length * 8;
        return "rgba(" + [shade,shade,shade,255].join(",") + ")";
    }

    constructor(props) {
        super(props);
        this.state = {
            background : this.calcBackground(),
            foreground : this.calcForeground(),
        };
    };

    handleMouseEnter () {
        this.setState({
            background : "black",
            foreground : "white" 
        });
    }

    handleMouseLeave () {
        console.log(this)
        this.setState({
            background : this.calcBackground(),
            foreground : this.calcForeground() 
        });
    }

    render() {
        const {label} = this.props;
        const href = this.props.url || "/" + this.props.name;

        return (
            <a className="MenuButton" style={
                {
                    backgroundColor: this.state.background,
                    color: this.state.foreground
                }}
                href={ href }
                onMouseEnter={() => this.handleMouseEnter()}
                onMouseLeave={() => this.handleMouseLeave()}
                >
                {label} 
            </a>
        )
    }
};

export default MenuButton;