import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SocialButton.css";

class SocialButton extends Component {
    static propTypes = {
        label : PropTypes.string,
        icon : PropTypes.any,
        url : PropTypes.string
    };

    render () {
            return (
                <span class="social-button">
                    <a href={this.props.url} target="_blank" class="social-link" rel="noreferrer">
                        <FontAwesomeIcon icon={this.props.icon} />
                    </a>
                </span>
            )    
    }
}

export default SocialButton;