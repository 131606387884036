import React, { Component } from "react";
import PropTypes from "prop-types";
import './Event.css';

class Event extends Component {
    static propTypes = {
        title: PropTypes.string,
        date: PropTypes.string,
        time: PropTypes.string,
        location: PropTypes.string,
        url: PropTypes.string,
        description: PropTypes.string,
        index: PropTypes.number
    };

    static defaultProps = {
        title: null,
        date: null,
        time: "",
        location: null,
        url: null,
        description: "",
        index: 0
    };

    render () {
        return (
            <div className={
                (this.props.index % 2 === 0) ? "Event-main" : "Event-main-odd"
            }>
                <p>
                    <span className="Event-date">{this.props.date}</span>&nbsp;&nbsp;
                    <span className="Event-time">{this.props.time}</span><br />
                    <span className="Event-title">
                        {this.props.title}
                    </span><br />
                    <span>{this.props.location}</span>
                </p>
                <p className="Event-body">
                    {this.props.children}
                </p>
            </div>
        )
    }
};


export default Event;
