import React, { Component } from "react";
import "./index.css";
import Event from "./Event";

class Events extends Component {

    render () {
        return (
            <div>
                <h2 className="blog-title">UPCOMING EVENTS</h2>
                <Event
                    index="0"
                    title="San Francisco Electronic Music Festival"
                    date="Friday, September 20, 2024"
                    time="8PM"
                    location="The Lab, 2948 16th Street, San Francisco"
                    url="http://www.sfemf.org/"
                    >
                    Amanda Chaudhary is proud to open the three nights of the main program 2024 San Francisco Electronic Music Festival!  
                    She will blend wild experimental modular-synthesizer explorations with jazzy and funky rhythms and theatrical elements.
                </Event>
                
                <h2 className="blog-title">PAST EVENTS</h2>
                <Event
                    index="1"
                    title="3 Encounters"
                    date="Friday, April 12, 2024"
                    time="8PM"
                    location="Center for New Music, 55 Taylor Street San Francisco"
                    url="https://centerfornewmusic.com/event/3-encounters/"
                    >
                    An evening of 3 short sets: Amanda Chaudhary (electronics) , Zachary Watkins (guitar and electronics), 
                    followed with a duo with Thea Farhadian (violin & electronics) with Silvia Matheus (electronics). 
                    Three 25-minute sets by three musicians/composers based in the Bay Area.
                </Event>
                <Event 
                    index="0"
                    title="Wonder Valley Experimental Music Festval" 
                    date="Saturday, April 6, 2024" 
                    time="5PM-12AM" 
                    location="The Palms Restaurant, 83131 Amboy Road, Twentynine Palms, CA"
                    url="https://wvexperimental.info/">
                    Solo electronic set as part of this annual festival in the desert north of Joshua Tree.  
                    Amanda will play a blend of noise, objects, jazz and fusion sounds, and more.
                </Event>
            </div>
        )
    }
};

export default Events;