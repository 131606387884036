import { recordings } from "./Music"
import { Terminal, useEventQueue, textLine, textWord, commandWord, anchorWord } from 'crt-terminal';
import styles from "./CommandLine.module.css";
import { articles } from "./Press";

const bannerText = 'Type "help" for available commands'


const commands = {
    meow: {
        command: "meow",
        description: " draw a cat",
        function: meow
    },
    music: {
        command: "music",
        description: "print list of available recordings",
        function: music
    },
    press: {
        command: "press",
        description: "print list of press articles",
        function: press
    },
    echo: {
        command: "echo",
        description: " print arguments on the console",
        function: echo
    },
    help: {
        command: "help",
        description: " print this list of commands",
        function: help
    }
}

const blank = textLine({
    words: [textWord({characters:""})]
})

function parseCommands(commandLine, print) {
    const regex = /("[^"\\]*(?:\\[\S\s][^"\\]*)*"|'[^'\\]*(?:\\[\S\s][^'\\]*)*'|\/[^\/\\]*(?:\\[\S\s][^\/\\]*)*\/[gimy]*(?=\s|$)|(?:\\\s|\S)+)/g;
    let tokens = commandLine.match(regex)
    const [command, ...args] = tokens
    if (commands[command]) {
        const commandFunction = commands[command].function
        commandFunction(args,print)
    } else {
        help(args, print)
    }
}   

function music(args, print) {
    var textLines = []
    for (const key in recordings) {
        const recording = recordings[key]
        console.log(recording)
        textLines.push(
            textLine({
                words: [
                    textWord({ characters: recording.title }),
                    textWord({ characters: " (" + recording.artist + ", " + recording.year + ")" })
                ]
            })
        )
        textLines.push(
            textLine({
                words: [
                    anchorWord({ characters: recording.url, href: recording.url })
                ]
            })
        )
        textLines.push(blank)
    }
    print(textLines)
}

function press(args, print) {
    var textLines = []
    for (const article of articles) {
        textLines.push(
            textLine({
                words: [
                    textWord({ characters: article.name })
                ]
            })
        )
        textLines.push(
            textLine({
                words: [
                    anchorWord({ characters: article.title, href: article.url })
                ]
            })
        )
        textLines.push(blank)
    }
    print(textLines)
}

function help(args, print) {

    var textLines = [
        textLine({
            words: [textWord({ characters: "Available commands:"})]
        }),
        blank
    ]
    for (var key in commands) {
        const commandObject = commands[key]
        let line = textLine({
            words: [
                commandWord({characters: commandObject.command}),
                textWord({characters: "\t" + commandObject.description})
            ]
        })
        textLines.push(line)
    }

    print(textLines)
}

function echo(args, print) {
    const words = args.map ((arg) => {
        return textWord({characters: arg + " "})
    })
    console.log(words)
    print([
        textLine({
            words: words
        })
    ])
}

function meow(args, print) {
    print([
        textLine({
            words: [textWord({ characters: "Meow meow!"})]
        })
    ])
}


function CommandLine () {
    const eventQueue = useEventQueue();
    const { print } = eventQueue.handlers;

    return (
        <div className={styles.terminalContainer} >
            <Terminal
                queue={eventQueue}
                banner={[textLine({ words: [textWord({ characters: bannerText })] })]}
                onCommand={(command) =>
                    parseCommands(command, print)
                }
            />
        </div>
    )
}

export default CommandLine;