import React, { Component } from "react";
import { Gallery } from "react-gallery-grid";

function importAll(r) {
    return r.keys().map(r);
}
   

const images = importAll(require.context('./gallery', false, /\.(png|jpe?g|svg)$/));


class Photos extends Component {

    constructor(props) {
        super(props);
        this.state = { photos: [] };
      }

    getImgBlob(src) {
        const resp = fetch(src);
        resp.then((response) => {
            const blob = response.blob()
            if (response.ok) {
                blob.then((imgBlob) => {
                    createImageBitmap(imgBlob)
                        .then((bmp) => {
                            const { width, height } = bmp
                            console.log(src, width,height);
                            const photo = {
                                url: src,
                                width: width,
                                height: height
                            }               
                            this.setState((props,state) => {
                                console.log(this.state.photos)
                                return {
                                    photos: this.state.photos.concat([photo])
                                }
                            })
                        }, (rejection) => {
                            console.log(rejection)
                        })
                })
            } else {
                return Promise.reject(response.status)
            }
        })
    }

    prepareImages () {
        var result = []
        for (const image of images) {
            console.log(image)
            this.getImgBlob(image)
        }
        return result;
    }

    componentDidMount() {
        this.prepareImages()
    }

    // <Gallery photos={this.state.photos} targetRowHeight={300} /> 
    render() {
        console.log("render", this.state.photos)
        return (
            <div>
               <Gallery
                    items={this.state.photos}
                    itemRenderer={({ item, size }) => (
                        <img src={item.url} width={size.width} height={size.height} alt=""/>
                    )}
                    rowHeightRange={{ min: 200, max: 350 }}
                    gap={8}
                    preserveAspectRatio={true}
                />
            </div>
        );
    }
}

export default Photos;