import React, { Component } from "react";
import "./index.css";
import Events from "./Events";
import { ShortMusic } from "./Music";
import "./Home.css";
import Box from "./Box";

class Home extends Component {

    render () {
        return (
            <div className="Home">
                <div className="row">
                    <div className="col">
                        <Box>
                            <div style={{maxWidth: "500px", textAlign: "center", alignContent: "center"}}>
                                <div>
                                    <a href="https://amandachaudhary.bandcamp.com/album/january-suborbital-denomination">
                                        <img src="photos/amanda_chaudhary_jsd_cd_cover.jpg" width="400px" alt="album cover of Amanda Chaudhary's January Suborbital Denoimination"/>
                                    </a>
                                </div>
                                <div style={{margin: "10px"}}>
                                        <p>Amanda's latest release <i>January Suborbital Denomination</i> <br />is now available as a CD or digital download!</p>
                                        <p><a href="https://amandachaudhary.bandcamp.com/album/january-suborbital-denomination">Buy/listen on Bandcamp</a></p>
                                </div>
                            </div>
                        </Box>
                    </div>
                    <div className="col">
                        <Box>
                            <div style={{maxWidth: "500px", textAlign: "center", alignContent: "center"}}>
                                <div>
                                    <img style={{maxWidth: "400px"}} src="photos/amanda_chaudhary_sfemf.jpg" alt="Amanda Chaudhary, San Francisco Electronic Music Festival, September 20, 2024.8PM" />
                                </div>
                                <div style={{margin: "10px"}}>
                                    The San Frarncisco Electronic Music Festival.  <br />September 20, 2024. 8PM
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Box>
                            <ShortMusic />
                        </Box>
                    </div>
                    <div className="col">
                        <Box>
                            <Events />
                        </Box>
                    </div>
                </div>
            </div>
        );
    }
};

export default Home;