import React, { Component } from "react";
import PropTypes from "prop-types";
import './App.css';
import MenuButton from "./MenuButton";
import Home from "./Home";
import { Music } from "./Music";
import Bio from "./Bio";
import Events from "./Events";
import Contact from "./Contact";
import Photos from "./Photos";
import Press from "./Press";
import CommandLine from "./CommandLine";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { faBandcamp, faYoutube, faInstagram, faFacebook, faThreads } from '@fortawesome/free-brands-svg-icons'
import SocialButton from "./SocialButton";

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.5
}; 

const AnimationLayout = () => {
  const { pathname } = useLocation();
  return (
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
  );
};

class App extends Component  {

  static propTypes = {
    title : PropTypes.string,
    header : PropTypes.string
  };

  static defaultProps = {
    title : "Amanda Chaudhary",
    header : "AMANDA CHAUDHARY"
  }


  render() {
      return (
        <div className="App">
          <div className="App-header">
          <div class="social-buttons">
                <SocialButton icon={faBandcamp} label="Bandcamp" url="https://amandachaudhary.bandcamp.com" />
                <SocialButton icon={faYoutube} label="YouTube" url="https://www.youtube.com/channel/UCjymz9Ri10S8qZzt74AgFkQ/" />
                <SocialButton icon={faInstagram} label="Instagram" url="https://www.instagram.com/catsynth/"/>
                <SocialButton icon={faFacebook} label="Facebook" url="https://www.facebook.com/amanda.chaudhary.music" />
                <SocialButton icon={faThreads} label="Threads" url="https://www.threads.net/@catsynth" />
           </div>
            <h1>
              <a href="/" className="App-title">
                {this.props.header}
              </a>
            </h1>

            <div className="App-menu">
              <MenuButton label="MUSIC" name="music"></MenuButton>
              <MenuButton label="CATSYNTH TV" url="https://youtube.com/CatSynthTV"></MenuButton>
              <MenuButton label="EVENTS" name="events"></MenuButton>
              <MenuButton label="ABOUT" name="bio"></MenuButton>
              <MenuButton label="PRESS" name="press"></MenuButton>
              <MenuButton label="CONTACT" name="contact"></MenuButton>
              <MenuButton label="PHOTOS" name="photos"></MenuButton>
              <MenuButton label="CATSYNTH BLOG" url="https://www.catsynth.com"></MenuButton>
              <MenuButton label="COMMAND LINE" name="command-line" />
            </div>

          </div>
            <div className="App-body">
                    <Routes>
                      <Route element={<AnimationLayout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="music" element={<Music />} />
                        <Route path="bio" element={<Bio />} />
                        <Route path="events" element={<Events />} />
                        <Route path="contact" element={<Contact />} />
                        <Route path="photos" element={<Photos />} />
                        <Route path="press" element={<Press />} />
                        <Route path="command-line" element={<CommandLine />} />
                      </Route>
                    </Routes>
            </div>
            <div class="App-footer">
              © 2024 Amanda Chaudhary.  All rights reserved
            </div>
        </div>
      );
  }

};

export default App;

