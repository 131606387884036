import React, { Component } from "react";
import "./Box.css";

class Box extends Component {

    render() {
        return (
            <div className="box">
                <div className="box-contents">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default Box;